import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type State = {
  baseUrl: string;
  isSocial: boolean;
  enabledSocialConnections: string[];
  showPublicSections: boolean;
};

export const siteSlice = createSlice({
  name: 'site',
  initialState: {
    baseUrl: '',
    isSocial: false,
    isSSOMandatory: false,
    enabledSocialConnections: [] as string[],
    showPublicSections: false,
  },
  reducers: {
    setBaseUrl(state, { payload }: PayloadAction<string>) {
      state.baseUrl = payload;
    },
  },
});
